import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from 'ng2-validation';
import {ToastrService} from 'ngx-toastr';
import {AuthRepository} from '@core/authentication/auth-repository.service';
import {AuthenticationService} from '@core/authentication/authentication.service';
import {RegisterUser} from '@model/auth/RegisterUser';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  userCreated = false;
  rUser = new RegisterUser();
  sms: string;
  error = null;
  smsText = 'Kod SMS jest wymagany do aktywowania konta';
  modal = false;
  btnLoading = false;

  regForm: FormGroup;
  smsForm: FormGroup;

  constructor(fb: FormBuilder,
              private toastr: ToastrService,
              private authenticationService: AuthenticationService,
              private authRepository: AuthRepository) {

    const loginPassword = new FormControl('', Validators.required);
    const loginPasswordConfirm = new FormControl('', CustomValidators.equalTo(loginPassword));

    this.regForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
      'phone': ['', Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(9)])],
      'password': loginPassword,
      'confirmPassword': loginPasswordConfirm
    });

    this.smsForm = fb.group({
      'number': [null, Validators.compose([Validators.required])]
    });

  }

  submitForm($ev, form: FormGroup) {
    this.userCreated = false;
    $ev.preventDefault();
    let value = form.value;
    for (let c in form.controls) {
      form.controls[c].markAsTouched();
    }
    if (!this.modal) {
      this.sms = null;
      this.rUser = new RegisterUser();
      this.rUser.name = value.name;
      this.rUser.email = value.email;
      this.rUser.password = value.password;
      this.rUser.phone_number = value.phone;
      this.register();
    } else {
      this.sms = value.number;
      this.sendSms();
    }

  }

  ngOnInit() {
    this.authenticationService.logout(false);
  }

  register(): void {
    this.resetSmsText();
    this.authRepository.register(this.rUser)
    .subscribe(success => {
      setTimeout(() => {
        this.showModal();
      }, 300);
    }, error => {
      this.setError(error.error.error);
    });
  }

  setError(message: string) {
    message = this.translate(message);
    this.error = null;
    this.toastr.error(message);
    setTimeout(() => {
      this.error = message;
    }, 300);
  }

  sendSms() {
    this.authRepository.sendSms(this.sms, this.rUser.phone_number)
    .subscribe(success => {
      this.toastr.success('Możesz się zalogować', 'Numer potwierdzony');
      this.modal = false;
      this.userCreated = true;
    }, error => {
      this.setError(this.translate(error.status));
    });
  }

  showModal() {
    this.modal = true;
  }

  hideModal() {
    this.modal = false;
  }

  resetSmsText() {
    this.smsText = 'Kod SMS jest wymagany do aktywowania konta';
  }

  private translate(msg: string): string {
    switch (msg) {
      case 'PHONE_ALREADY_TAKEN':
        return 'Podany numer jest zajęty';
      case 'EMAIL_ALREADY_TAKEN':
        return 'Podany adres e-mail jest zajęty';
      case 'CODE_INCORRECT':
        return 'Podany kod SMS jest nieprawidłowy';
      case 'USER_NOT_FOUND':
        return 'Nie znaleziono użytkownika';
      case 'EMAIL_ALREADY_TAKEN_UNDER_VERIFICATION':
        return 'Wprowadzony e-mail został już użyty podczas rejestracji, która nie została potwierdzona kodem sms. Odczekaj 10 minut i ponów ponownie proces rejestracji';
    }

    return msg;
  }
}
